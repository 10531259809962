<template>
  <div>
    <header-m></header-m>
    <div id="website_download_wrap" class="recruitment">
      <!-- banner -->
      <div class="banner">
        <div id="join_banner">
          <a>
            <span class="banner_img"></span>
          </a>
        </div>
        <div class="banner_main banner_main_download">
          <h3>加入我们</h3>
        </div>
      </div>
      <div class="content">
        <div class="littleIcon">
          <div class="nof">
            <h1>五险一金</h1>
          </div>
          <div class="nof">
            <h1>定期体检</h1>
          </div>
          <div class="nof">
            <h1>员工旅游</h1>
          </div>
          <div class="nof">
            <h1>节日礼品</h1>
          </div>
        </div>
        <ul class="joinBox clearfix">
          <li class="joinBoxLiActive">
            <img src="../assets/img/join_us01.jpg" />
          </li>
          <li class="joinBoxLiActive">
            <h2>户外活动</h2>
          </li>
          <li>
            <h2>节日聚会</h2>
          </li>
          <li><img src="../assets/img/join_us02.jpg" /></li>
          <li><img src="../assets/img/join_us03.jpg" /></li>
          <li>
            <h2>定期培训</h2>
          </li>
          <li>
            <h2>亲子活动</h2>
          </li>
          <li><img src="../assets/img/join_us04.jpg" /></li>
        </ul>
        <!-- <div class="activity_wrap">
          <ul class="activity">
            <li>
              <div class="big ani">
                <img src="../assets/img/join_us01.jpg" />
                <span class="arrow"></span>
              </div>
              <div class="small">
                <h1>运动会</h1>
                <h2>SPORTS FESTIVAL</h2>
              </div>
              <div class="small ani">
                <img src="../assets/img/join_us02.jpg" />
                <span class="arrow arrow2"></span>
              </div>
              <div class="small red">
                <h1>女神节</h1>
                <h2>GODDESS FESTIVAL</h2>
              </div>
            </li>
            <li>
              <div class="small ani">
                <img src="../assets/img/join_us03.jpg" />
                <span class="arrow arrow1"></span>
              </div>
              <div class="small train">
                <h1>员工培训</h1>
                <h2>STAFF TRAINING</h2>
              </div>
              <div class="big ani">
                <img src="../assets/img/join_us04.jpg" />
                <span class="arrow"></span>
              </div>
              <div class="small">
                <h1>家庭日</h1>
                <h2>FAMILY DAY</h2>
              </div>
            </li>
          </ul>
        </div> -->
      </div>
    </div>
    <footer-content></footer-content>
  </div>
</template>
<script>
// import '@/assets/js/flexible'
import HeaderM from "../components/HeaderM.vue";
import FooterContent from "@/components/FooterContent.vue";
import { isMobile } from '@/utils/util'
export default {
  name: "JoinM",
  components: {
    HeaderM,
    FooterContent
  },
  data() {
    return {};
  },
  created() {},
  mounted() {
    if (isMobile()) {
      // alert("手机端");
      // this.$router.replace('/joinm');
    } else {
      // alert("pc端");
      this.$router.replace('/join');
    }
  },
  methods: {}
};
</script>

<style scoped>
@import "../assets/css/index.css";
.banner {
  min-width: 300px;
}
.banner_img {
  background: url("../assets/img/join_bg1.jpg") no-repeat center top;
}
.recruitment .content .littleIcon {
  width: 100%;
}
.recruitment .content .littleIcon .nof {
  margin: 20px 0px;
  width: 42%;
}
.banner .banner_main.banner_main_download {
  width: 100%;
  margin-left: 0;
}
.banner .banner_main {
  left: 0;
  margin-left: 0;
}
.joinBox {
  width: 100%;
  display: block;
  border: 1px solid #eeeeee;
  box-shadow: 24px 24px 30px 0 rgb(0 0 0 / 5%);
  margin-bottom: 30px;
  margin-top: 50px;
  overflow: hidden;
}
.joinBox li {
  float: left;
  width: 50%;
  text-align: center;
  height: 120px;
  position: relative;
  overflow: hidden;
}
.joinBox li img {
  width: 100%;
}
.joinBox li h2 {
  margin-top: 40px;
  font-size: 18px;
}
.joinBox li p {
  font-size: 14px;
  color: #bdbdbd;
  margin-top: 10px;
}
.joinBox li.joinBoxLiActive{
  height: 90px;
}
</style>
